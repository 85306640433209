<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">

        <el-row :gutter="20">
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span class="title">刊例提报信息</span>
                <el-button type="text" icon="el-icon-refresh" @click="getItemInfo" :loading="loading" style="font-size: 1.2em"></el-button>
                <el-button class="fanhui-button"
                           type="text"
                           icon="iconfont icon-back"
                           style="float: right;font-size: 1.6em"
                           title="返回上级页面"
                           size="mini"
                           @click="goBack">返回
                </el-button>

<!--                <el-button type="text" icon="el-icon-s-fold" @click="goBack"-->
<!--                           style="float: right;font-size: 1.6em"></el-button>-->
              </div>
              <div class="plc-info">
                <div class="plc-item flex2">
                  <el-avatar :size="80" :src="formData.cover_pc_url" @error="true">
                    <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                  </el-avatar>
                  <span class="tag-alias">{{ formData.tag_alias || '无标签' }}</span>
                  <span class="nickname">{{ formData.nickname }}</span>
                  <div>
                    <!--                    <span class="is-label">分组：</span>-->
                    <span class="dept_name">{{ formData.dept_name || '/' }}</span>
                  </div>

                  <div style="width: 120px">
                    <span  v-html="formData.intro"></span>
                  </div>
                </div>
                <div class="plc-item flex3">
                  <div style="min-width: 160px">
                    <div class="mb5">
                      <span class="is-label">平台：</span>
                      <span class="is-value">{{ formData.platform_name || '/' }}</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">平台ID：</span>
                      <span class="is-value">{{ formData.account_id || '/' }}</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">时间：</span>
                      <span class="is-value">
                        {{ formData.year || '/' }}年{{ formData.month || '/' }}月
                    </span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">粉丝：</span>
                      <span class="is-value"> {{
                          formData.fans_total >= 10000 ? `${$utils.numberFormat(formData.fans_total / 10000, 2)} 万` : formData.fans_total
                        }}</span>
                    </div>
                    <div class="mb5">
                      <el-tag type="primary" class="p-service" v-if="formData.opened_star==='Y'">开通星图</el-tag>
                      <el-tag type="primary" class="p-service" v-if="formData.opened_window==='Y'">开通橱窗</el-tag>
                      <el-tag type="primary" class="p-service" v-if="formData.is_partner==='Y'">为品牌合作人</el-tag>
                      <el-tag type="primary" class="p-service" v-if="formData.opened_cart==='Y'">开通购物车</el-tag>
                    </div>

                    <!--                  <div>-->
                    <!--                    <span class="is-label">粉丝：</span>-->
                    <!--                    <span class="is-value">{{ $utils.numberFormat(formData.fans_total / 10000, 2) }} 万</span>-->
                    <!--                  </div>-->
                    <div class="mb5">
                      <span class="is-label">当月广告订单数：</span>
                      <span class="is-value">{{ formData.order_total || '/' }}</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">下月广告订单数：</span>
                      <span class="is-value">{{ formData.next_order_total || '/' }}</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">下月底预估粉丝：</span>
                      <span class="is-value">{{ $utils.numberFormat(formData.pre_fans_total / 10000, 2) }} 万</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">最近的广告排期：</span>
                      <span class="is-value">{{ formData.last_release_date || '/' }}</span>
                    </div>

                    <div class="mb5">
                      <span class="is-label">所在地区：</span>
                      <span class="is-value">{{ formData.city || '/' }}</span>
                    </div>
                    <div class="mb5">
                      <span class="is-label">主页链接：</span>
                      <el-link type="primary" @click="openUrl(formData.home_page_link)">
                        {{ formData.home_page_link || '/' }}
                      </el-link>
                    </div>
                  </div>
                </div>
                <div class="plc-item flex4">
                  <div style="text-align: center;margin-bottom: 8px">
                    <span class="is-label">当月价格</span>
                  </div>
                  <div class="quote">
                    <PriceGrids :rows="formData.current_quote"/>
                  </div>
                </div>
                <div class="plc-item flex4">
                  <div style="text-align: center;margin-bottom: 8px">
                    <span class="is-label">下月预估价格</span>
                  </div>
                  <PriceGrids :rows="formData.next_quote"/>
                </div>
                <div class="plc-item flex4">
                  <div style="text-align: center;margin-bottom: 8px">
                    <span class="is-label">商业化营销中心终价</span>
                  </div>
                  <PriceGrids :rows="formData.real_quote"/>
                </div>
                <div class="plc-item flex3">
                  <div style="text-align: center;margin-bottom: 8px">
                    <span class="is-label">商务案例</span>
                  </div>
                  <p v-html="formData.bs_case"></p>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <br/>
        <ApplyPanel :plc-item-id="plcItemId" v-if="userPermissions.indexOf('plc_check')>-1"/>
      </div>
    </PageHeaderLayout>

  </div>

</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PriceGrids from '@/pages/publication/PriceGrids'
import { mapGetters } from 'vuex'
import ApplyPanel from '@/pages/publication/ApplyPanel'

export default {
  name: 'PlcItemDetail',
  components: { ApplyPanel, PriceGrids, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      plcItemId: null,
      loading: false,
      formData: {},
    }
  },
  methods: {
    //刷新一行数据
    async getItemInfo() {
      this.formData = {}
      let id = this.plcItemId || null
      let {info} = await this.$api.getPublicationItemInfo(id)
      this.formData = info
    },
    openUrl(href) {
      let url = ['http://', 'https://', '//'].indexOf(href) > -1 ? href : `https://${href}`
      window.open(url, '_blank')
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  created() {
    this.plcItemId = this.$route.params && this.$route.params.id
    this.getItemInfo()
  }
}
</script>

<style scoped>
.title {
  font-size: 1.4em;
  font-weight: 800;
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}

.plc-info {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.plc-item {
  display: flex;
  flex-direction: column;
  /*border: #00feff 1px dashed;*/
  padding: 10px;
  /*flex: 4;*/
  border-radius: 10px;
  background: #303133;
  color: #ffffff;
  margin: 5px;
  position: relative;
}

.p-service {
  margin: 5px;
}

.intro {
  /**
  首行缩进
   */
  /*text-indent: 2em;*/
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px 5px;
}

.quote {
  /*border: #00feff 1px dashed;*/
}

.nickname {
  margin-top: 10px;
  margin-bottom: 10px;
}

/**
红人标签
 */
.tag-alias {
  position: absolute;
  font-size: 10px;
  font-weight: 800;
  text-align: center;
  /*padding: 2px;*/
  right: 5%;
  top: 5%;
  height: 16px;
  width: 40px;
  /*border: #F56C6C 1px solid;*/
  color: #F56C6C;
  opacity: 0.9;
}

</style>
